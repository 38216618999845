import { Link } from 'gatsby'
import styled from 'styled-components'

import { Color } from './theme'
import { heading3Style } from './typography'

const PaginationLink = styled(Link)`
  ${heading3Style({ noColor: true })};
  color: ${Color.PURPLE_5};
  text-align: center;
  display: block;
  margin: 0 auto;
  flex: 1;
`

export const PaginationOuter = styled.nav`
  display: flex;
  align-content: space-around;
`

export default PaginationLink
