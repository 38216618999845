import { useState, useEffect } from 'react'

export const getHubspotutk = (): string | null =>
  document.cookie.split('hubspotutk=')[1]?.split(';')[0]?.trim() || null

const obtainHubspotutk = function (callback: (hutk: string) => void): void {
  const hutk = getHubspotutk()
  if (hutk) {
    callback(hutk)
  } else {
    setTimeout(getHubspotutk.bind(null, callback), 100)
  }
}

const useHubspotutk = (): string | null => {
  const [hutk, setHutk] = useState<string | null>(null)

  useEffect(() => obtainHubspotutk(setHutk), [setHutk])

  return hutk
}

export default useHubspotutk
