import { Link } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'

import { maxScreenWidth } from './mixins'
import { Color } from './theme'
import { calloutRegularStyle, calloutSemiboldStyle } from './typography'

const Outer = styled.nav`
  padding: 15px 0;
  margin-bottom: 16px;
  text-align: center;
  border: solid 0px #ecedf0;
  border-width: 1px 0;
  display: flex;
  flex-wrap: nowrap;
  overflow-y: auto;
  justify-content: center;
  ${maxScreenWidth(615)(css`
    justify-content: flex-start;
  `)}

  a {
    flex: 0 0 auto;
    padding: 0 12px;
  }
`

const Item = styled.span<{ color?: string; isActive: boolean }>`
  ${calloutRegularStyle({ noColor: true, noResponsive: true })}
  color: ${Color.GRAY_6};

  ${({ color, isActive }) =>
    isActive &&
    css`
      ${calloutSemiboldStyle({ noColor: true, noResponsive: true })}
      color: ${color ? `#${color}` : 'black'};
    `}
`

type Props = {
  rootPath: string
  activeCategorySlug?: string
  categories: {
    slug?: string | null | undefined
    name?: string | null | undefined
    color?: string | null | undefined
  }[]
}

const CategoriesNav = ({ rootPath, activeCategorySlug, categories }: Props) => (
  <Outer>
    <Link to={rootPath}>
      <Item isActive={!activeCategorySlug}>{'Latest'}</Item>
    </Link>
    {categories.map((category, index) => (
      <Link
        to={`${rootPath}/category/${category.slug}`}
        key={category.slug || index}
      >
        <Item
          isActive={activeCategorySlug === category.slug}
          color={category.color || undefined}
        >
          {category.name}
        </Item>
      </Link>
    ))}
  </Outer>
)

export default CategoriesNav
