import type { ComponentPropsWithRef } from 'react'
import React from 'react'

import useIntercomUserId from '../hooks/useIntercomUserId'

const IntercomUserIdInput = ({
  name = 'user_id',
  ...props
}: ComponentPropsWithRef<'input'>) => {
  const userId = useIntercomUserId()

  return <input {...props} type={'hidden'} name={name} value={userId || ''} />
}

export default IntercomUserIdInput
