import type { ComponentProps } from 'react'
import React, { useEffect, useState } from 'react'
import supportsWebP from 'supports-webp'

import type {
  BasicAssetFragment,
  FullAssetFragment,
} from '../../../graphql-types'

const LOW_IMAGE_QUALITY = 30
const HIGH_IMAGE_QUALITY = 70
let defaultImageQuality = LOW_IMAGE_QUALITY
let defaultPixelRatio = 1
let defaultWebpOk = true

type AssetFragment = BasicAssetFragment & FullAssetFragment
type UseAssetUrlOptions = {
  asset?: AssetFragment | null
  maxWidth?: number | null
  maxHeight?: number | null
}

// const isJpg = (asset?: AssetFragment | null): boolean => asset?.contentType?.startsWith('image/jp')
const isPng = (asset?: AssetFragment | null): boolean =>
  !!asset?.file?.contentType?.startsWith('image/png')
const isSvg = (asset?: AssetFragment | null): boolean =>
  !!asset?.file?.contentType?.startsWith('image/svg')

export const useAssetUrl = ({
  asset,
  maxWidth,
  maxHeight,
}: UseAssetUrlOptions): string => {
  const [{ imageQuality, pixelRatio, webpOk }, setOptions] = useState({
    imageQuality: defaultImageQuality,
    pixelRatio: defaultPixelRatio,
    webpOk: defaultWebpOk,
  })

  useEffect(() => {
    defaultImageQuality = HIGH_IMAGE_QUALITY
    defaultPixelRatio = window.devicePixelRatio
    supportsWebP
      .then((supported) => {
        defaultWebpOk = supported
        setOptions({
          imageQuality: defaultImageQuality,
          pixelRatio: defaultPixelRatio,
          webpOk: defaultWebpOk,
        })
        return true
      })
      .catch(console.error)
  }, [setOptions])

  let src = asset?.file?.url || ''

  if (!src.includes('?')) {
    src += '?'
  }

  if (!isSvg(asset)) {
    // Lower quality
    src += `q=${imageQuality || defaultImageQuality}`
    // Optimal format
    src +=
      webpOk || defaultWebpOk
        ? '&fm=webp'
        : isPng(asset)
        ? '&fm=png'
        : '&fm=jpg&fl=progressive'
    // Resize
    if (maxWidth) {
      src += `&w=${Math.round(maxWidth * (pixelRatio || defaultPixelRatio))}`
    } else if (maxHeight) {
      src += `&h=${Math.round(maxHeight * (pixelRatio || defaultPixelRatio))}`
    }
  }

  return src
}

type Props = Omit<ComponentProps<'img'>, 'image' | 'alt'> &
  Partial<Pick<ComponentProps<'img'>, 'alt'>> & {
    asset?: AssetFragment | null
    maxWidth?: number | null
    maxHeight?: number | null
  }

// https://www.contentful.com/developers/docs/references/images-api/#/reference/retrieval/image
const Img = ({ asset, alt, maxWidth, maxHeight, style, ...props }: Props) => {
  const src = useAssetUrl({ asset, maxWidth, maxHeight })

  style = style || {}

  if (maxHeight) {
    style.maxHeight = maxHeight
  }

  return asset?.file?.url ? (
    <img
      src={src}
      title={asset?.title || asset?.description || ''}
      alt={alt || asset?.description || asset?.title || ''}
      style={style}
      {...props}
    />
  ) : null
}

export default Img
