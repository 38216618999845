import { Link } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'

import type { PreviewPostFragment } from '../../../graphql-types'
import { tablet, mobile, maxScreenWidth } from '../mixins'
import { useAssetUrl } from '../modules/Img'
import Spacer from '../modules/Spacer'
import { Color } from '../theme'
import { Heading3, BodyRegular, CalloutSemibold } from '../typography'

import { getExcerpt } from './utils'

export const Excerpt = BodyRegular

export const Image = styled.div<{ imageSrc: string }>`
  background-color: ${Color.GRAY_1};
  background-image: url(${({ imageSrc }) => imageSrc});
  background-size: cover;
  background-position: center;
  border-radius: 20px;
`

const Outer = styled.div`
  flex: 0 0 ${(368 / 1200) * 100}%;

  ${Excerpt} {
    margin: 16px 0 0;
  }

  a {
    display: flex;
    flex-direction: column;
  }

  ${tablet(css`
    flex-basis: 48%;
  `)}

  ${mobile(css`
    flex-basis: 100%;
  `)}
`

const PreviewImage = styled(Image)`
  height: 276px;
  margin-bottom: 32px;

  ${tablet(css`
    height: 204px;
  `)}
  ${maxScreenWidth(530)(css`
    margin-bottom: 24px;
  `)}
`

const PreviewCategory = styled(CalloutSemibold)<{ color?: string | null }>`
  margin-bottom: 8px;
  ${({ color }) =>
    color &&
    css`
      color: #${color};
    `}
`

type Props = {
  post: PreviewPostFragment
  noExcerpt?: boolean
}

const Preview = ({ post, noExcerpt }: Props) => {
  const assetSrc = useAssetUrl({ asset: post.poster, maxHeight: 276 })

  return (
    <Outer>
      <Link to={`/blog/${post.slug}`}>
        <PreviewImage imageSrc={assetSrc} />
        <PreviewCategory
          noColor
          noResponsive
          color={post.category?.color || undefined}
          dangerouslySetInnerHTML={{ __html: post.category?.name || '&nbsp;' }}
        />
        <Heading3 noResponsive>{post.title}</Heading3>
        {!noExcerpt && <Excerpt>{getExcerpt(post)}</Excerpt>}
      </Link>
      <Spacer size={'Medium'} />
    </Outer>
  )
}

export default Preview
