import type { ComponentProps } from 'react'
import React from 'react'
import styled, { css } from 'styled-components'

import type { HeroFragment } from '../../../../graphql-types'
import { maxScreenWidth, mobile, tablet } from '../../mixins'
import { Color } from '../../theme'
import { Heading1, SubheadRegular } from '../../typography'
import { renderTextWithLineBreaksAndB } from '../../utils'
import Img from '../Img'
import Link from '../Link'

import SignUpWidget from './SignUpWidget'

const Outer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 56px;

  ${tablet(css`
    flex-direction: column;
  `)}
`

const Inner = styled.div<{ centered?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ centered }) =>
    !!centered &&
    css`
      text-align: center;
      justify-content: center;
      max-width: 720px;
      margin: 0 auto;
    `}
`

const ImgOuter = styled.div`
  flex: 0 0 ${(640 / 1200) * 100}%;
  margin: 30px 0 30px 80px;

  ${maxScreenWidth(1132)(css`
    margin-left: 24px;
  `)}

  ${tablet(css`
    flex: 0 0 auto;
    margin-left: 0;
  `)}
`

const Image = styled(Img)`
  max-width: 100%;
`

const Title = styled(Heading1)`
  & + * {
    margin-top: 24px;
  }

  b {
    color: ${Color.PURPLE_4};
  }
`

const LinksOuter = styled.div`
  width: 100%;
  margin-top: 52px;
  z-index: 2;

  & > * {
    margin-bottom: 12px;
  }

  & > *:first-child {
    margin-right: 24px;
  }

  ${mobile(css`
    margin-top: 24px;

    & > * {
      width: 100%;
      margin: 0;
    }

    & > * + * {
      margin: 12px 0;
    }
  `)}
`

type Props = Omit<HeroFragment, '__typename'> &
  Partial<Pick<HeroFragment, '__typename'>> &
  ComponentProps<typeof Outer>

const Hero = ({
  __typename,
  title,
  text,
  image,
  widgetId,
  links,
  ...props
}: Props) => (
  <Outer {...props}>
    <Inner centered={!image}>
      {!!title && <Title>{renderTextWithLineBreaksAndB(title)}</Title>}
      {!!text?.text && (
        <SubheadRegular>
          {renderTextWithLineBreaksAndB(text.text)}
        </SubheadRegular>
      )}
      {!!links?.length && (
        <LinksOuter>
          {links?.map(
            (link, index) => !!link && <Link key={index} {...link} />,
          )}
        </LinksOuter>
      )}

      {widgetId === 'sign-up' && <SignUpWidget buttonId={'header-cta'} />}
    </Inner>
    {!!image && (
      <ImgOuter>
        <Image asset={image} maxWidth={640} />
      </ImgOuter>
    )}
  </Outer>
)

export default Hero
