import styled, { css } from 'styled-components'

import { minScreenWidth } from '../mixins'
import Container from '../modules/Container'

const Grid = styled(Container)<{ itemsCount: number }>`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;
  ${({ itemsCount }) =>
    itemsCount < 3 &&
    minScreenWidth(1000)(css`
      justify-content: flex-start;
      & > * + * {
        margin-left: 48px;
      }
    `)}
`

export default Grid
