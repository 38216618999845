import React from 'react'
import styled from 'styled-components'

import NewsletterForm from './NewsletterForm'
import { Container } from './layout'
import { Heading3 } from './typography'

const Outer = styled(Container)`
  flex-flow: column;
  text-align: center;
  padding-bottom: 128px;
`

const Form = styled(NewsletterForm)`
  margin-left: auto;
  margin-right: auto;
`

const NewsletterSignUp = (): JSX.Element => (
  <Outer column>
    <Heading3>
      {'Subscribe to get occasional emails about productivity and delegation.'}
    </Heading3>
    <Form />
  </Outer>
)

export default NewsletterSignUp
