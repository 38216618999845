import styled, { css } from 'styled-components'

import type { SpacerFragment } from '../../../graphql-types'
import { mobile } from '../mixins'

export enum SpacerSize {
  ULTRA_SMALL = 'Ultra-Small',
  EXTRA_SMALL = 'Extra-Small',
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large',
}

const heightForSize = (
  size: SpacerSize | null = SpacerSize.MEDIUM,
  mobile = false,
): number => {
  if (!size) {
    size = SpacerSize.MEDIUM
  }

  switch (size) {
    case SpacerSize.ULTRA_SMALL:
      return 32
    case SpacerSize.EXTRA_SMALL:
      return mobile ? 32 : 52
    case SpacerSize.SMALL:
      return 72
    case SpacerSize.MEDIUM:
      return mobile ? 72 : 128
    case SpacerSize.LARGE:
      return mobile ? 128 : 192
  }
}

type Props = Omit<SpacerFragment, '__typename'> &
  Partial<Pick<SpacerFragment, '__typename'>>

const Spacer = styled.div<Props>`
  flex: 0 0 auto;
  height: ${({ size }) => heightForSize(size as SpacerSize)}px;
  ${({ size }) =>
    mobile(css`
      height: ${heightForSize(size as SpacerSize, true)}px;
    `)}
`

export default Spacer
