import type { PreviewPostFragment, PostFragment } from '../../../graphql-types'

export function formatDate(date: undefined | string): string {
  if (!date) {
    return ''
  }

  const [year, month, day] = date.split('-')
  const localDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
  return localDate.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
}

export function getExcerpt(post: PreviewPostFragment | PostFragment): string {
  if (post.excerpt?.excerpt) {
    return post.excerpt?.excerpt
  }
  if ('body' in post && post.body?.body) {
    return `${post.body.body.substr(0, 220)}...`
  }

  return ''
}

export function getCurrentLimit(queryString: string): number {
  const m = /[?&]limit=(\d+)/.exec(queryString)
  if (m?.[1]) {
    return parseInt(m[1])
  }
  return 0
}

export function queryStringWithLimit(
  queryString: string,
  limit: number,
): string {
  let q = queryString.replace(/&?limit=\d*/, '')
  if (!q.startsWith('?')) {
    q = '?' + q
  }
  if (limit) {
    q = `${q}&limit=${limit}`.replace('?&', '?')
  }
  return q
}

export function hasFeaturedArticle(categorySlug: string | undefined): boolean {
  return !categorySlug
}
