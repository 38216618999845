import React, { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'

import useHubspotutk from '../hooks/useHubspotutk'

import IntercomUserIdInput from './IntercomUserIdInput'
import { Button as _Button, EmailInput, getFormValues } from './forms'
import { tablet, mobile } from './mixins'
import { Color } from './theme'
import { BodyRegular } from './typography'

const HUBSPOT_PORTAL_ID = '21978218'
const HUBSPOT_EMAIL_SIGN_UP_FORM_ID = '8e3f98f2-59dd-48a3-b7a3-3b72671e0c10'
const HUBSPOT_SUBMIT_FORM_ACTION = `https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_PORTAL_ID}/${HUBSPOT_EMAIL_SIGN_UP_FORM_ID}`
const FORM_METHOD = 'POST'

const Outer = styled.div`
  margin-top: 32px;
  max-width: 472px;

  ${tablet(css`
    margin-left: auto;
    margin-right: auto;
  `)}
`

const Fields = styled.div`
  display: flex;
  justify-content: center;

  ${mobile(css`
    flex-direction: column;
  `)}
`

const Confirmation = styled(BodyRegular)`
  color: ${Color.GRAY_6};
`

const Input = styled(EmailInput)`
  margin-right: 16px;
  margin-bottom: 16px;
  flex: 1;
  padding: 14px;

  ${mobile(css`
    margin-right: 0;
  `)}
`

const Button = styled(_Button)`
  margin-bottom: 16px;
`

interface NewsletterFormProps {
  className?: any
}

const NewsletterForm = ({ className }: NewsletterFormProps): JSX.Element => {
  const [isValid, setValid] = useState(false)
  const [submitedEmail, setSubmitedEmail] = useState('')
  const hutk = useHubspotutk()

  const submitForm = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      // @ts-ignore
      const values = getFormValues(e.target)

      const fields = Object.entries(values)
        .filter(([_, value]) => Boolean(value))
        .map(([name, value]) => ({ name, value }))

      const context: {
        hutk?: string
        pageUri: string
        pageName: string
      } = {
        pageUri: window.location.href,
        pageName: document.title,
      }

      if (hutk) {
        context.hutk = hutk
      }

      window
        .fetch(HUBSPOT_SUBMIT_FORM_ACTION, {
          method: FORM_METHOD,
          body: JSON.stringify({
            fields,
            context,
          }),
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
        .then(console.info)
        .catch(console.error)

      setSubmitedEmail(values.email || '')
    },
    [hutk],
  )

  return (
    <form
      method={FORM_METHOD}
      action={HUBSPOT_SUBMIT_FORM_ACTION}
      onSubmit={submitForm}
    >
      <Outer className={className}>
        <Fields>
          <IntercomUserIdInput />
          {submitedEmail ? (
            <Confirmation>{"You're all set!"}</Confirmation>
          ) : (
            <>
              <Input
                placeholder={'Your email'}
                name={'email'}
                onValid={setValid}
                required
              />
              <Button
                title={'Sign up'}
                isValid={isValid}
                id={'blog-subscribe'}
              />
            </>
          )}
        </Fields>
      </Outer>
    </form>
  )
}

export default NewsletterForm
