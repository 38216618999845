import { useState, useEffect } from 'react'

export const getIntercomUserId = function (
  callback: (userId: string) => void,
): void {
  // @ts-expect-error
  const visitorId = window.Intercom?.('getVisitorId')
  if (visitorId) {
    callback(visitorId)
  } else {
    setTimeout(getIntercomUserId.bind(null, callback), 100)
  }
}

const useIntercomUserId = (): string | null => {
  const [userId, setUserId] = useState<string | null>(null)

  useEffect(() => getIntercomUserId(setUserId), [setUserId])

  return userId
}

export default useIntercomUserId
