import type { ComponentProps } from 'react'
import React from 'react'
import styled, { css } from 'styled-components'

import type { LinkFragment } from '../../../graphql-types'
import { Color } from '../theme'
import type { Typography } from '../typography'
import { BodySemibold, CalloutSemibold, Heading3 } from '../typography'

const A = styled.a<{
  button?: boolean
  isCompact?: boolean
  isSecondary: boolean
}>`
  display: inline-block;
  transition: color 200ms ease, background-color 200ms ease;
  text-align: center;

  ${({ button, isCompact, isSecondary }) =>
    button
      ? css`
          background-color: ${Color.PURPLE_5};
          color: ${Color.WHITE};
          padding: ${isCompact ? '9px 16px' : '13px 40px'};
          border-radius: 12px;
          box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1),
            0px 1px 4px rgba(0, 0, 0, 0.1),
            inset 0px -2px 2px rgba(0, 0, 0, 0.05);

          &:hover {
            color: ${Color.WHITE};
            background-color: ${Color.PURPLE_6};
          }

          ${isSecondary &&
          css`
            background: #ffffff;
            color: ${Color.PURPLE_5};
          `}
        `
      : css`
          color: ${Color.PURPLE_5};
          &:hover {
            color: ${Color.PURPLE_6};
          }
        `}

  & > * {
    display: inline;
  }
`

type Props = LinkFragment &
  ComponentProps<typeof A> &
  ComponentProps<Typography> & {
    TextComponent?: Typography
    href?: string
  }

const Link = ({
  __typename,
  href,
  url,
  button,
  buttonIsSecondary,
  label,
  newTab,
  isCompact,
  TextComponent,
  noResponsive,
  children,
  ...props
}: Props) => {
  const finalHref = url || href

  if (!finalHref) {
    return null
  }

  TextComponent =
    TextComponent ||
    (button ? (isCompact ? CalloutSemibold : BodySemibold) : Heading3)

  return (
    <A
      href={finalHref}
      button={!!button}
      isCompact={isCompact}
      isSecondary={!!buttonIsSecondary}
      {...(newTab
        ? {
            target: '_blank',
            rel: 'noopener noreferrer',
          }
        : {})}
      {...props}
    >
      <TextComponent noColor noResponsive={noResponsive}>
        {label}
        {children}
      </TextComponent>
    </A>
  )
}

export default Link
