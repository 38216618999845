import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import React from 'react'

import type {
  BlogPostsListQuery,
  BlogPostsListQueryVariables,
} from '../../../graphql-types'
import NewsletterSignUp from '../../components/NewsletterSignUp'
import PaginationLink, {
  PaginationOuter,
} from '../../components/PaginationLink'
import CategoriesNav from '../../components/blog/CategoriesNav'
import FeaturedPreview from '../../components/blog/FeaturedPreview'
import Preview from '../../components/blog/Preview'
import Grid from '../../components/blog/PreviewsGrid'
import Page from '../../components/layout/Page'
import Hero from '../../components/modules/Hero'
import Spacer from '../../components/modules/Spacer'

export type Query = BlogPostsListQuery
export type QueryVariables = BlogPostsListQueryVariables
export type Context = QueryVariables & {
  categorySlug: string | null
  nextPagePath?: string | null
  prevPagePath?: string | null
}

export const query = graphql`
  query BlogPostsList(
    $langKey: String!
    $where: ContentfulBlogPostFilterInput
    $limit: Int
    $skip: Int
    $categorySlug: String
  ) {
    category: contentfulBlogCategory(
      node_locale: { eq: $langKey }
      slug: { eq: $categorySlug }
    ) {
      name
      slug
    }
    list: allContentfulBlogPost(
      limit: $limit
      skip: $skip
      filter: $where
      sort: { fields: publishedOn, order: DESC }
    ) {
      items: nodes {
        ...PreviewPost
      }
    }
  }
`

type Props = PageProps<Query, Context>

const BlogPostsList = ({ data, pageContext }: Props) => {
  const posts = data.list.items
  const { category } = data
  const { nextPagePath, prevPagePath } = pageContext

  return (
    <Page
      head={{
        title: 'Blog',
        description: 'Take back your day',
      }}
    >
      <CategoriesNav activeCategorySlug={category?.slug} />

      {!!category?.name && (
        // Category title as header
        <>
          <Hero title={category?.name} />
          <Spacer size={'Medium'} />
        </>
      )}

      <Grid itemsCount={posts.length}>
        {posts.map((post, index) =>
          index === 0 && posts.length > 9 ? (
            <FeaturedPreview key={post.slug} post={post} />
          ) : (
            <Preview key={post.slug} post={post} />
          ),
        )}
      </Grid>

      {!!(prevPagePath || nextPagePath) && (
        <>
          <PaginationOuter>
            {!!prevPagePath && (
              <PaginationLink to={prevPagePath}>
                {'← Previous articles'}
              </PaginationLink>
            )}
            {!!nextPagePath && (
              <PaginationLink to={nextPagePath}>
                {'Next articles →'}
              </PaginationLink>
            )}
          </PaginationOuter>
          <Spacer size={'Large'} />
        </>
      )}
      <NewsletterSignUp />
    </Page>
  )
}

export default BlogPostsList
