import { Link as NavLink } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'

import type { PreviewPostFragment } from '../../../graphql-types'
import { tablet, mobile } from '../mixins'
import { useAssetUrl } from '../modules/Img'
import Link from '../modules/Link'
import Spacer from '../modules/Spacer'
import { Heading1, BodySemibold } from '../typography'

import { Excerpt, Image } from './Preview'
import { getExcerpt } from './utils'

export const Outer = styled.div`
  flex: 0 0 100%;
  margin: 56px 0 0;

  ${Excerpt} {
    margin: 24px 0;
  }

  a {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ${tablet(css`
      flex-direction: column-reverse;
    `)}

    ${mobile(css`
      flex-direction: column;
    `)}
  }
`

const FeaturedPreviewImage = styled(Image)`
  flex: 0 0 ${(640 / 1200) * 100}%;

  ${tablet(css`
    flex-basis: 276px;
  `)}
  ${mobile(css`
    flex-basis: 204px;
  `)}
`

const FeaturedPreviewText = styled.div`
  flex: 0 0 ${(480 / 1200) * 100}%;
  padding: 40px 0;
  ${tablet(css`
    flex-basis: auto;
    padding-bottom: 0;1
  `)}
  ${mobile(css`
    padding: 0 0 24px;
  `)}
`

type Props = {
  post: PreviewPostFragment
}

const FeaturedPreview = ({ post }: Props) => {
  const assetSrc = useAssetUrl({ asset: post.poster, maxHeight: 700 })

  return (
    <Outer>
      <NavLink to={`/blog/${post.slug}`}>
        <FeaturedPreviewText>
          <Heading1>{post.title}</Heading1>
          <Excerpt>{getExcerpt(post)}</Excerpt>
          <Link
            label={'Keep reading →'}
            url={`/blog/${post.slug}`}
            TextComponent={BodySemibold}
          />
        </FeaturedPreviewText>
        <FeaturedPreviewImage imageSrc={assetSrc} />
      </NavLink>
      <Spacer size={'Medium'} />
    </Outer>
  )
}

export default FeaturedPreview
