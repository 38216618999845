import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'

import type { BlogCategoriesQuery } from '../../../graphql-types'
import _CategoriesNav from '../CategoriesNav'

export const query = graphql`
  fragment BlogCategory on ContentfulBlogCategory {
    name
    color
    slug
  }

  query BlogCategories {
    categories: allContentfulBlogCategory(
      sort: { fields: order, order: ASC }
      filter: { node_locale: { eq: "en" } }
    ) {
      nodes {
        ...BlogCategory
      }
    }
  }
`

type Props = {
  activeCategorySlug?: string
}

const CategoriesNav = ({ activeCategorySlug }: Props) => {
  const data = useStaticQuery<BlogCategoriesQuery>(query)

  return (
    <_CategoriesNav
      rootPath={'/blog'}
      activeCategorySlug={activeCategorySlug}
      categories={data.categories.nodes}
    />
  )
}

export default CategoriesNav
